<template>
  <div class="addMenu">
    <a-modal v-model="show" :title="title" :footer="null" :maskClosable="false" @cancel="close">
      <div>
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="服务板块名称" prop="serverBindingName">
            <a-input placeholder="请输入服务板块名称，不超过10个字符" v-model="form.serverBindingName" :maxLength="10" />
          </a-form-model-item>
          <!-- <a-form-model-item label="状态" prop="status" required>
            <a-radio-group v-model="form.status" :options="plateStatusOptions" />
          </a-form-model-item> -->
        </a-form-model>
      </div>
      <div style="text-align: center">
        <a-button @click="close">
          取消
        </a-button>
        <a-button style="margin-left: 20px" type="primary" @click="save">
          保存
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import help from '../help';
import * as api from '@/api/entrepreneurClubManagement/serverManagement';
export default {
  props: {
    operationType: {
      type: String,
      default: () => 'add'
    },
    isShow: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    title() {
      return this.operationType === 'add' ? `新增服务板块` : '编辑服务板块';
    }
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      plateStatusOptions: help.plateStatusOptions,
      show: false,
      form: {
        serverBindingName: '',
        status: 1,
      },
      rules: {
        serverBindingName: [
          { required: true, message: "请输入服务板块名称", trigger: "blur" },
        ]
      }
    };
  },
  methods: {
    /**网络请求 */
    // 添加服务板块
    async addPlate() {
      try {
        const res = await api.addServicePlate(this.form);
        if (res.code === this.$SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.$emit('refreshList');
          this.close();
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
        this.$message.error('操作失败');
      }
    },
    // 查询服务模块详情
    async getPlateDetail(serverBindingId) {
      try {
        const res = await api.getServicePlateDetail({ serverBindingId });
        if (res.code === this.$SUCCESS_CODE) {
          this.form = {
            ...res.data
          }
        }
      } catch (error) {

      }
    },
    // 编辑服务模块
    async editPlate() {
      try {
        const res = await api.editServicePlate(this.form);
        if (res.code === this.$SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.$emit('refreshList');
          this.close();
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
        this.$message.error('操作失败');
      }
    },
    close() {
      this.$emit('update:isShow', false);
    },
    save() {
      console.log(this.form);
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.operationType === 'add') {
            this.addPlate();
          } else {
            this.editPlate();
          }
        }
      });
    },
  },
  created() {
    this.show = this.isShow;
    if (this.id) {
      this.getPlateDetail(this.id);
    }
  },
};
</script>

<style lang="scss" scoped></style>