// 板块状态枚举
const PlateStatusEnum = {
  ENABLE: 1, // 启用
  DISABLED: 0, // 禁用
};

// 板块状态文案枚举
const PlateStatusTextEnum = {
  1: '启用',
  0: '禁用',
};

// 板块状态选项
const plateStatusOptions = [
  {
    value: PlateStatusEnum.ENABLE,
    label: PlateStatusTextEnum[PlateStatusEnum.ENABLE],
  },
  {
    value: PlateStatusEnum.DISABLED,
    label: PlateStatusTextEnum[PlateStatusEnum.DISABLED],
  },
];

const plateSable = {
  dataInfo: [],
  columns: [
    {
      title: '服务板块名称',
      dataIndex: 'serverBindingName',
      key: 'serverBindingName',
      width: '200px',
    },
    {
      title: 'H5链接',
      key: 'h5Url',
      dataIndex: 'h5Url',
      scopedSlots: { customRender: 'h5Url' },
      // width: '700px',
    },
    // {
    //   title: '状态',
    //   dataIndex: 'status',
    //   key: 'status',
    //   customRender: (text) => PlateStatusTextEnum[text],
    //   width: '100px'
    // },
    {
      title: '创建人',
      dataIndex: 'createUser',
      key: 'createUser',
      width: '150px'
    },
    {
      title: '创建时间',
      dataIndex: 'createDateTime',
      key: 'createDateTime',
      width: '200px'
    },
    {
      title: '更新人',
      dataIndex: 'updateUser',
      key: 'updateUser',
      width: '150px'
    },
    {
      title: '更新时间',
      dataIndex: 'updateDateTime',
      key: 'updateDateTime',
      width: '200px'
    },
    {
      title: '操作',
      key: 'action',
      width: '150px',
      scopedSlots: { customRender: 'action' },
      fixed: 'right'
    },
  ],
  //分页数据
  pagination: {
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  },
};

const plateSearchForm = {
  serverBindingName: '', // 服务板块名称
  status: undefined, // 状态
};

export default {
  PlateStatusEnum,
  PlateStatusTextEnum,
  plateStatusOptions,
  plateSable,
  plateSearchForm,
};
