<template>
  <div class="service-plate-management-container">
    <ds-header title="服务板块管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="服务板块名称">
        <a-input v-model.trim="searchForm.serverBindingName" placeholder="请输入服务板块名称" class="width200"
          label-width="70px" />
      </a-form-item>
      <!-- <a-form-item label="状态">
        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="searchForm.status"
          class="width200" placeholder="全部">
          <a-select-option v-for="(item, index) in plateStatusOptions" :key="index" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item> -->
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px"
      v-if="$checkButtonPermissions($buttonPermissionsConfig.entrepreneurClubManagement.serverManagement.servicePlateManagement.add)">
      <a-button type="primary" @click="goServicePlateEdit('add')">
        <a-icon type="plus" />
        添加服务板块
      </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table :rowKey="(record, index) => index" :columns="table.columns" :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false" bordered @change="pageChange" :scroll="{ x: 1700 }">
        <span slot="h5Url" slot-scope="text, record" class="h5Url">
          <span>{{ record.h5Url }}</span>
          <a-button type="link" @click="copyH5url(record.h5Url)" style="margin-right: 20px;">复制</a-button>
        </span>
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="goServicePlateEdit('edit', record.serverBindingId)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.entrepreneurClubManagement.serverManagement.servicePlateManagement.edit)">
            编辑
          </a-button>
          <!-- <a-button class="btnClass" type="link" @click="updateState(record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.entrepreneurClubManagement.serverManagement.servicePlateManagement.enable)">
            {{
          record.status === PlateStatusEnum.ENABLE ? PlateStatusTextEnum[PlateStatusEnum.DISABLED] :
            PlateStatusTextEnum[PlateStatusEnum.ENABLE] }} </a-button> -->
          <!-- <a-button class="btnClass" type="link" @click="remove(record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.entrepreneurClubManagement.serverManagement.servicePlateManagement.delete)">
            删除
          </a-button> -->
          <a-button class="btnClass" type="link" @click="bindingService(record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.entrepreneurClubManagement.serverManagement.servicePlateManagement.binding)">
            绑定
          </a-button>
        </span>
      </a-table>
    </div>
    <AddServicePlate v-if="show" :isShow.sync="show" :operationType="operationType" :id="id" @refreshList="getList" />
  </div>
</template>

<script>
import help from './help';
import AddServicePlate from './components/AddServicePlate.vue';
import * as api from '@/api/entrepreneurClubManagement/serverManagement';
import pageRules from "@/utils/pagingConfig/pageUtils.js";
// import BindingAggregationPage from './components/BindingAggregationPage.vue'
export default {
  name: 'servicePlateManagement',
  components: {
    AddServicePlate,
    // BindingAggregationPage
  },
  data() {
    return {
      searchForm: {
        ...help.plateSearchForm
      },
      table: { ...help.plateSable },
      PlateStatusTextEnum: help.PlateStatusTextEnum,
      PlateStatusEnum: help.PlateStatusEnum,
      plateStatusOptions: help.plateStatusOptions,
      show: false,
      operationType: 'add',
      id: '',
    };
  },

  methods: {
    /**网络请求 */
    // 获取服务模块列表
    async getList() {
      try {
        const res = await api.getServicePlateList({
          ...this.searchForm,
          pageNo: this.table.pagination.current,
          pageSize: this.table.pagination.pageSize
        });
        if (res.code === this.$SUCCESS_CODE) {
          this.table.dataInfo = res.data.data;
          this.table.pagination.total = res.data.total;
        }
      } catch (error) {

      }
    },
    // 设置服务模块状态
    async setStatus(serverBindingId, status) {
      try {
        const res = await api.setServicePlateStatus({ serverBindingId, status });
        if (res.code === this.$SUCCESS_CODE) {
          this.getList();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        this.$message.error('操作失败');
      }
    },
    // 删除服务模块
    async deletePlate(serverBindingId) {
      try {
        const res = await api.deleteServicePlate({ serverBindingId });
        if (res.code === this.$SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.table.pagination.current = pageRules.pageRules(
            this.table.dataInfo.length,
            1,
            this.table.pagination.current
          );
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {

      }
    },
    search() {
      this.table.pagination.current = 1;
      this.getList();
    },
    resetSearchForm() {
      this.searchForm = {
        ...help.plateSearchForm
      }
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.getList();
    },
    async copyH5url(h5Url) {
      try {
        navigator.clipboard.writeText(h5Url);
        this.$message.success('复制成功')
      } catch (error) {

      }
    },
    goServicePlateEdit(type, id = '') {
      this.id = id;
      this.operationType = type;
      this.show = true;
    },
    updateState(record) {
      const self = this;
      this.$confirm({
        title: `确定要${record.status === help.PlateStatusEnum.ENABLE ? help.PlateStatusTextEnum[help.PlateStatusEnum.DISABLED] : help.PlateStatusTextEnum[help.PlateStatusEnum.ENABLE]}当前服务模块吗？`,
        onOk() {
          self.setStatus(record.serverBindingId, record.status === help.PlateStatusEnum.ENABLE ? help.PlateStatusEnum.DISABLED : help.PlateStatusEnum.ENABLE);
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    remove(record) {
      const self = this;
      this.$confirm({
        title: '确定要删除当前服务模块吗？',
        onOk() {
          self.deletePlate(record.serverBindingId);
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    bindingService(record) {
      this.$router.push({
        path: '/entrepreneurClubManagement/serverManagement/bindingAggregationPage',
        query: {
          serverBindingId: record.serverBindingId,
          serverBindingName: record.serverBindingName
        }
      })
    },
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
    getContainer() {

      return this.$refs.servicePlateManagement;
    }
  },
  created() {
    this.getList();
  },
  // 进入路由添加缓存
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch("operationCachedRoutes/updateAliveRoutes", ["servicePlateManagement"]);
    });
  },
  // 离开清除缓存
  beforeRouteLeave(to, from, next) {
    if (
      to.path !== "/entrepreneurClubManagement/serverManagement/bindingAggregationPage"
    ) {
      this.searchForm = {
        ...help.searchForm
      }
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.$store.dispatch("operationCachedRoutes/updateAliveRoutes", []);
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.width200 {
  width: 200px;
}

.btnClass {
  padding: 0px;
  padding-right: 5px;
}

.h5Url {
  // min-width: 650px;
  display: flex;
  justify-content: space-between;
}
</style>